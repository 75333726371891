.picture-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  position: relative;
}

.half {
  height: 60vh;
  max-height: 60vw;
  position: relative;
}

.title {
  color: #fff;
  font-size: max(40px, 10vmin);
  font-family: "Archivo", sans-serif;
  text-align: center;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  opacity: 1;
}

.photographer {
  font-family: "Barlow Condensed";
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 999;
  color: rgb(170, 170, 170);
  font-weight: 400;
  font-size: 13px;
}

/* URLs for the pictures used */
.url-1 {
  background: no-repeat url("https://i.imgur.com/XNgyK92.jpg") center
    center/cover;
}

.url-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/0zUcIbO.jpg") center center/cover;
}

.url-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/NwyJzQK.jpg") center center/cover;
}

.url-4 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/fwF4P6y.jpg") bottom center/cover;
}

.url-5 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/SEvJXda.jpg") center center/cover;
  background-position: 0 40%;
}
