.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Archivo", sans-serif;
  font-size: min(3vh, 1.5rem);
}

.btn-primary {
  background-color: #fff;
  color: #242424;
  border: 1px solid #fff;
}

.btn-outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
}

.btn-large {
  padding: 12px 26px;
}

.btn-medium:hover,
.btn-large:hover {
  text-decoration: none;
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

/* for the "apply here" button in apply page */
.apply-here,
.apply-here:focus {
  background-color: #00843d;
  border: 0;
  color: #fff;
  border-radius: 100px;
  font-size: min(17px, 4vw);
  text-decoration: none;
  box-shadow: none;
  outline: 0;
}

.apply-here:hover,
.apply-here:active {
  text-decoration: none;
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}