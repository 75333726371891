* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Roboto, sans-serif;
  color: #fff;
}

/* Always shows scrollbar on all pages */
body {
  overflow-y: scroll;
  background-color: #030303;
}

.page {
  padding: 0 6vw 3vw 6vw;
}

html,
body,
#root,
.App {
  height: 100vh;
}

.main-text {
  font-family: "Archivo", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: max(3vw, 2rem);
  z-index: 9;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: 0 20%;
  width: 60%;
}

.apply-text {
  font-weight: 500;
  font-size: 50px;
}

.contact-social {
  font-size: max(32px, 2vw);
}

.contact-social-spacing {
  margin-right: min(50px, 10%);
}

/* Text in center when on mobile */
@media screen and (max-width: 960px) {
  .main-text {
    text-align: center;
    margin: 0 10%;
    width: 80%;
  }
}

/* Page has to finish loading before showing animation */
.js-loading *,
.js-loading *:before,
.js-loading *:after {
  -webkit-animation-play-state: paused !important;
          animation-play-state: paused !important;
  opacity: 0;
}

/* css to fade in text when loading page*/
.fade-in {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 1.5s;
  transition: linear;
}

@keyframes fadein {
  from {
    opacity: 0;
    left: -5%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
    left: -5%;
  }
  to {
    opacity: 1;
    left: 0;
  }
}

/* Internet Explorer */

/* Opera < 12.1 */

.picture-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  position: relative;
}

.half {
  height: 60vh;
  max-height: 60vw;
  position: relative;
}

.title {
  color: #fff;
  font-size: max(40px, 10vmin);
  font-family: "Archivo", sans-serif;
  text-align: center;
  position: absolute;
  top: 50%; 
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 100%;
  opacity: 1;
}

.photographer {
  font-family: "Barlow Condensed";
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 999;
  color: rgb(170, 170, 170);
  font-weight: 400;
  font-size: 13px;
}

/* URLs for the pictures used */
.url-1 {
  background: no-repeat url("https://i.imgur.com/XNgyK92.jpg") center
    center/cover;
}

.url-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/0zUcIbO.jpg") center center/cover;
}

.url-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/NwyJzQK.jpg") center center/cover;
}

.url-4 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/fwF4P6y.jpg") bottom center/cover;
}

.url-5 {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    no-repeat url("https://i.imgur.com/SEvJXda.jpg") center center/cover;
  background-position: 0 40%;
}

.FAQ-container {
  position: relative;
  padding: 5vw 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 80vw;
}

.footer-container {
  height: min(82px, 30vw);
  padding: 0 6vw;
  margin-top: 80px;
  position: -webkit-sticky;
  position: sticky;
}

.creator-container {
  color: inherit;
  text-align: left;
  font-size: min(16px, 4vw);
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.icon-scaling {
  font-size: min(30px, 7vw);
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: "Archivo", sans-serif;
  font-size: min(3vh, 1.5rem);
}

.btn-primary {
  background-color: #fff;
  color: #242424;
  border: 1px solid #fff;
}

.btn-outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
}

.btn-large {
  padding: 12px 26px;
}

.btn-medium:hover,
.btn-large:hover {
  text-decoration: none;
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}

/* for the "apply here" button in apply page */
.apply-here,
.apply-here:focus {
  background-color: #00843d;
  border: 0;
  color: #fff;
  border-radius: 100px;
  font-size: min(17px, 4vw);
  text-decoration: none;
  box-shadow: none;
  outline: 0;
}

.apply-here:hover,
.apply-here:active {
  text-decoration: none;
  background: #fff;
  color: #242424;
  transition: all 0.3s ease-out;
}
.navbar {
  background: transparent;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  position: absolute;
  top: 0;
  z-index: 999;
  padding: 0 40px;
}

/* Navbar when not on home page */
.not-home {
  position: static;
}

.navbar-logo {
  position: absolute;
  justify-self: left;
  margin-left: 0px;
  cursor: pointer;
  display: flex;
}

.logo {
  max-height: 70px;
  width: auto;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: auto;
}

/* Links to other pages internally */
.nav-links {
  font-family: "Barlow Condensed";
  margin: 0 15px 20px 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  height: 60px;
  line-height: 80px;
}

.nav-links:hover {
  color: #fff;
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  text-decoration: none;
}

.selected {
  border-bottom: 4px solid #fff;
}

.menu-icon {
  display: none;
  z-index: 999;
}

/* styling for smaller screens */
@media screen and (max-width: 960px) {
  .logo {
    max-height: 70px;
    height: 20vw;
    width: auto;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    top: 0px;
    left: -100%;
    transition: all 0.5s ease;
    position: absolute;
    z-index: 9999;
    opacity: 0.9;
  }

  .nav-menu-active {
    flex-direction: column;
    width: 100%;
    top: 0px;
    left: 0;
    transition: all 0.5s ease;
    position: absolute;
    display: flex;
    background-color: rgba(14, 14, 14, 0.95);
    transition: all 0.5s ease;
    z-index: 9999;
    opacity: 0.9;
  }

  .nav-links-mobile {
    background-color: rgba(14, 14, 14, 0.95);
    font-family: "Barlow Condensed";
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    line-height: 80px;
    text-align: center;
    text-decoration: none;
  }

  .nav-links-mobile:hover {
    background-color: rgba(255, 255, 255, 0.85);
    color: #242424;
    text-decoration: none;
  }

  .selected-mobile {
    color: #242424;
    background-color: rgba(255, 255, 255, 0.85);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 20px;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 30px;
    cursor: pointer;
    z-index: 999999;
  }

  .fa-bars {
    color: #fff;
    font-size: 30px;
  }

  .fa-times {
    color: #fff;
    font-size: 35px;
  }
}

.social-container {
  text-align: right;
  font-size: min(30px, 9vw);
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.url {
  cursor: pointer;
  color: #fff;
}

.url:hover {
  text-decoration: none;
  color: rgb(200, 200, 200);
}

.url:link,
.url:visited {
  text-decoration: none;
}

.spacing {
  margin-right: min(30px, 6vw);
}

.table-separate {
  border-right: solid 2px;
}

.text-section-container {
  padding: 3vw 0;
  position: static;
}

.text-section-title {
  display: inline-block;
  position: static;
  text-align: left;
  font-size: max(30px, min(35px, 4.5vh));
  font-weight: 200;
  border-bottom: 2px solid #00843d;
  margin-bottom: 1vw;
}

.text-section-text {
  font-size: max(16px, min(18px, 2.5vh));
  line-height: 1.7rem;
  white-space: pre-wrap;
}

.overflow {
  overflow: hidden;
}

.profile-container {
  margin: 30px 0;
  overflow: auto;
}

.board-image {
  height: max(38vw, 500px);
  border-radius: 8px;
}

.info-container {
  margin: 5px 30px;
}

.info-container-mobile {
  margin: 5px 0;
}

.name {
  font-size: max(2vw, 30px);
  margin-bottom: max(1.5vw, 10px);
  border-bottom: 2px solid #00843d;
  display: inline-block;
}

.board-title {
  font-size: max(1.5vw, 25px);
  margin-bottom: max(2vw, 15px);
}

.about {
  font-size: max(1vw, 15px);
  margin-bottom: max(2vw, 25px);
}

.favorite-exercise {
  font-size: max(1vw, 15px);
}

.favorite-text {
  font-weight: 600;
}

.image-leader {
  background: no-repeat url("https://i.imgur.com/W6aREVD.jpg") center
    center/cover;
}

.image-deputy {
  background: no-repeat url("https://i.imgur.com/W6aREVD.jpg") center
    center/cover;
}

.image-treasurer {
  background: no-repeat url("https://i.imgur.com/W6aREVD.jpg") center
    center/cover;
}

.image-social-responsible-1 {
  background: no-repeat url("https://i.imgur.com/W6aREVD.jpg") center
    center/cover;
}

.image-social-responsible-2 {
  background: no-repeat url("https://i.imgur.com/W6aREVD.jpg") center
    center/cover;
}

