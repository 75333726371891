.text-section-container {
  padding: 3vw 0;
  position: static;
}

.text-section-title {
  display: inline-block;
  position: static;
  text-align: left;
  font-size: max(30px, min(35px, 4.5vh));
  font-weight: 200;
  border-bottom: 2px solid #00843d;
  margin-bottom: 1vw;
}

.text-section-text {
  font-size: max(16px, min(18px, 2.5vh));
  line-height: 1.7rem;
  white-space: pre-wrap;
}
